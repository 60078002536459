<!--
 * @Author: your name
 * @Date: 2021-11-25 12:51:11
 * @LastEditTime: 2022-03-22 14:31:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/table.vue
-->
<template>
    <div class="table_module">
        <div class="chinese" v-if="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language=='1'">
            <div class="list_header">
                <div class="second" style="border: 1px solid #EBEEF5;" valign="middle">主要领域</div>
                <div style="border: 1px solid #EBEEF5;" valign="middle">学习与发展目标(一级)</div>
                <div style="border: 1px solid #EBEEF5;" valign="middle">学习与发展目标(二级)</div>
                <div class="second" style="border: 1px solid #EBEEF5;" valign="middle">年龄段</div>
                <div class="max" style="border: 1px solid #EBEEF5;" valign="middle">目标</div>
            </div>
            <div class="list_text">
                <div class="list_item" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.learningDevelopmentGoalsYtjjs" :key="index">
                    <div class="item second" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.mainAreas"></div>
                    <div class="item" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.mainTargetOne"></div>
                    <div class="item" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.mainTargetTwo"></div>
                    <div class="item second" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.ageTargetGrade"></div>
                    <div class="item_last max" style="border: 1px solid #EBEEF5;" valign="middle">
                        <div v-for="(v, i) in ageTargetValueFun(item.ageTargetValue)" :key="i">
                            <pre v-html="v"></pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="english" v-else>
            <div class="list_header">
                <div style="border: 1px solid #EBEEF5;" valign="middle">Areas of Language Learning</div>
                <div style="border: 1px solid #EBEEF5;" valign="middle">Focus Area</div>
                <div style="border: 1px solid #EBEEF5;" valign="middle">Age Group</div>
                <div class="max_en" style="border: 1px solid #EBEEF5;" valign="middle">Learning Goals</div>
            </div>
            <div class="list_text">
                <div class="list_item" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.learningDevelopmentGoalsYtjjs" :key="index">
                    <div class="item" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.mainAreas"></div>
                    <div class="item" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.ageTargetValueEn"></div>
                    <div class="item" style="border: 1px solid #EBEEF5;" valign="middle" v-html="item.ageTargetGrade"></div>
                    <div class="max_en" style="border: 1px solid #EBEEF5;" valign="middle">
                        <div v-for="(v, i) in ageTargetValueFun(item.ageTargetLearningGoals)" :key="i">
                            <pre v-html="v"></pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>
<script>
export default {
    props:["detailsData"],
    data() {
        return {

        }
    },
    mounted(){
        
    },
    methods:{
        ageTargetValueFun(v) {
            if (typeof v == "string") {
                // 单选
                return [v];
            }
            if (typeof v == "object") {
                // 多选
                return v;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .table_module{
        width: 1500px;

        .chinese{
            .list_header{
                display: flex;
                font-size: 30px;
                background-color: rgb(248, 248, 248);
                text-align: center;

                div{
                    padding:0 15px 0 15px;
                    height: 100px;
                    width: 300px;
                    align-items: center;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    border-right: none !important;
                }
                .second{
                    width: 200px;
                }
                .max{
                    border-right: 1px solid #EBEEF5 !important;
                    width: 500px;
                }

            }

            .list_text{
                max-height: 400px;
                overflow: hidden;
                overflow-y: scroll;
                // border: 1px solid red;

                .list_item{
                    display: flex;

                    .item{
                        padding: 15px;
                        width: 300px;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        border-right: none !important;
                    }

                    .item_last{
                        padding: 15px;
                        width: 300px;
                        text-align: center;
                    }
                    .second{
                        width: 200px;
                    }
                    .max{
                        border-right: 1px solid #EBEEF5 !important;
                        width: 500px;
                    }
                }
                
            }
        }

        .english{
            .list_header{
                display: flex;
                font-size: 30px;
                background-color: rgb(248, 248, 248);
                text-align: center;

                div{
                    padding:0 15px 0 15px;
                    height: 100px;
                    width: 300px;
                    align-items: center;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    border-right: none !important;
                }

                .max_en{
                    width: 500px;
                }

            }
            .list_text{
                max-height: 400px;
                overflow: hidden;
                overflow-y: scroll;
                font-size: 30px;
                // border: 1px solid red;

                .list_item{
                    display: flex;

                    .item{
                        padding: 15px;
                        width: 300px;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        border-right: none !important;
                    }

                    .max_en{
                        width: 500px;
                        text-align: center;
                        padding: 15px;
                        pre{
                            font-family: arial;
                        }
                    }
                }
                
            }
        }
    }
</style>

