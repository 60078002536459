<!--
 * @Author: your name
 * @Date: 2022-03-07 15:39:09
 * @LastEditTime: 2022-06-07 12:27:29
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/components/popup/index1.vue
-->
<template>
        <van-popup v-model="$store.state.showTemplatePop">
            <div class="body" v-if="situation === '1'">
                <div class="title">反思模版</div>
                <div class="content">
                    <!-- <p v-html="data.content"></p> -->
                    <pre v-html="data.content"></pre>
                </div>
                <div class="btn" @click="saveTemplate">
                    <van-button class="save">
                        <img class="doc" src="@/assets/img/Layer 83@2x.png" alt="">
                        <span>使用此模版</span>
                    </van-button>
                </div>
            </div>
            <div class="body body1" v-if="situation === '2'">
                <div class="title">提示</div>
                <div class="content">
                    暂无学生信息，请管理员在“运营管理-学生管理”添加学生信息，并在“运营管理-班级管理”添加学生列表。
                </div>
            </div>
            <van-icon @click="close" class="close" name="close" />
        </van-popup>
</template>
<script>

export default {
    data () {
        return {
            data:{
                content:""
            },
            situation:""
        }
    },
    mounted() {
        
    },
    methods:{
        //点击保存模版时候
        saveTemplate(){
            this.$emit("getTemplateData",this.data.content)
            this.$store.commit("setShowTemplatePop",false);
        },
        //点击X
        close(){
            this.$store.commit("setShowTemplatePop",false);
        }
    }
}
</script>
<style lang="scss" scoped>
    .van-popup{
        border-radius: 20px;
        overflow: initial;
        .body{
            width: 661px;
            height: 835px;
            padding:54px 34px 0  34px;
            box-sizing: border-box;

            .title{
                font-size: 40px;
                margin-bottom: 39px;
                text-align: center;   
                color: #1E1E1E;
                line-height: 53px;
                font-weight: bold; 
            }

            .content{
                height: 550px;
                // border: 1px solid red;
                overflow: hidden;
                overflow-y: scroll;

                pre {
                    white-space: pre-wrap;       /* CSS-3 */
                    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                    white-space: -pre-wrap;      /* Opera 4-6 */
                    white-space: -o-pre-wrap;    /* Opera 7 */
                    Word-wrap: break-word;       /* Internet Explorer 5.5+ */
                }
            }

            .btn{
                font-size: 30px;
                margin-top: 22px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;

                .save{
                    width: 291px;
                    border-radius: 15px;
                    background-color: #4DB3B3;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .doc{
                        width: 39px;
                        height: 51px;
                        vertical-align: middle;
                    }
                    span{
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
            }
        }
        .close{
            position: absolute;
            font-size: 80px;
            color: #f9f9f9;
            bottom: -120px;
            left: 300px;
            z-index: 100000;
        }
        .body1{
            height: 400px;
        }
    }
</style>
