<!--
 * @Author: your name
 * @Date: 2022-03-07 09:58:51
 * @LastEditTime: 2022-03-18 09:22:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/start.vue
-->
<template>
    <div class="start">
        
        <div v-for="(item,index) in reflectionAfterClassLatitudeVos" :key="index">
            <div class="principal_dimension" @click="showSmallStart(item,index)">
                <div class="title">{{item.reflectionAfterClassLatitude.name}}</div>
                <van-rate :readonly="!$store.state.IsDetails" @change="changeStart(item)" :icon="startIcon" :void-icon="startIconVoid" class="start" gutter="8px" size="22px" allow-half v-model="item.reflectionAfterClassLatitude.starts" />
                <div class="text" v-if="$store.state.IsDetails">{{item.reflectionAfterClassLatitude.startText}}</div>
            </div>
            <div v-if="item.flag">
                <div class="sub_dimension" v-for="(item1,index1) in item.reflectionAfterClassLatitudeDetails" :key="index1">
                    <div class="title">{{item1.name}}</div>
                    <van-rate :readonly="!$store.state.IsDetails" @change="changeSonStart(item)" :icon="smallStartIcon" :void-icon="smallStartIconVoid" class="start" gutter="8px" size="20px" allow-half v-model="item1.starts" />
                </div>
            </div>
        </div>
        <div class="see_more">
            <div class="open_or_close">
                <div class="text" @click="seeMore">{{text}}</div>
                <van-icon class="arrow-down" name="arrow-down" v-show="text == '查看详情'" />
                <van-icon class="arrow-down" name="arrow-up" v-show="text == '收起'" />
            </div>
            
            <div class="clear" v-if="$store.state.IsDetails" @click="clearStart">
                <div>清空评分</div>
                <van-icon class="arrow-down" name="replay" />
            </div>
        </div>
    </div>
</template>
<script>
// import { reflectApi } from "@/api/login/index.js";
export default {
    props:["reflectionAfterClassLatitudeVos"],
    data () {
        return {
            startIconVoid: require('../../../../assets/img/Layer 43@2x.png'),
            startIcon: require('../../../../assets/img/Layer 41@2x.png'),
            smallStartIconVoid: require('../../../../assets/img/Layer 81 copy@2x.png'),
            smallStartIcon: require('../../../../assets/img/start.png'),
            showDimension:false,
            text:"查看详情",
            startText:"要加油",
            showSmallIndex: -1,
        }
    },
    mounted() {
        console.log("55555",this.reflectionAfterClassLatitudeVos)
        console.log(this.$store.state.IsDetails)
    },
    methods:{
        //点击主维度显示子维度
        showSmallStart(item,index){
            console.log(item,index)
            this.reflectionAfterClassLatitudeVos.forEach((item1,index1) => {
                if(index1 === index) {
                    if(!item.flag) {
                        this.$set(item,"flag",true);
                        this.text = "收起";
                    }else{
                        this.$set(item,"flag",false);
                        this.text = "查看详情";
                    }
                }else{
                    this.$set(item1,"flag",false);
                }
            })
            
        },
        // 查看详情
        seeMore(){
            this.reflectionAfterClassLatitudeVos.forEach((item) => {
                // item.flag = !item.flag;
                if(this.text == '查看详情'){
                    this.$set(item,"flag",true);
                }
                if(this.text == '收起') {
                    this.$set(item,"flag",false);
                }
                
            })
            this.text = this.text == "查看详情"? "收起" : "查看详情"
        },
        //改变主维度
        changeStart(item){
            console.log(item);
            item.reflectionAfterClassLatitudeDetails.forEach(item1 => {
                item1.starts = item.reflectionAfterClassLatitude.starts
            })
            item.reflectionAfterClassLatitude.startText = this.textWord(item.reflectionAfterClassLatitude.starts)
        },
        //改变子维度
        changeSonStart(item){
            console.log("点击子维度的数据",item)
            let num = 0;
            item.reflectionAfterClassLatitudeDetails.forEach(item1 => {
                num += item1.starts
            })
            item.reflectionAfterClassLatitude.starts = num / item.reflectionAfterClassLatitudeDetails.length;
            item.reflectionAfterClassLatitude.starts = Math.floor(item.reflectionAfterClassLatitude.starts * 1000) / 1000 .toFixed(3);
            console.log(item.reflectionAfterClassLatitude.starts);
            item.reflectionAfterClassLatitude.startText = this.textWord(item.reflectionAfterClassLatitude.starts)
        },
        // 提示文字方法
        textWord(starts){
            let text = ""; // eslint-disable-line no-unused-vars
            if(starts > 0 && starts < 4){
                text = "要加油"
            }else if(starts >= 4 && starts<5){
                text = "还不错"
            }else if(starts >= 5 ){
                text = "完美！"
            }else{
                text = ""
            }
            return text
        },
        //重置
        clearStart(){
            this.$emit("clearStart",true)
        }
    },

}
</script>
<style lang="scss" scoped>
    .start{
        margin-top: 10px;
        .principal_dimension{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            
            .title{
                width: 230px;
                font-size: 32px;
                font-weight: bold;
                color: #808080;
                // border: 1px solid red;
                // flex-grow:1
            }

            .start{
                margin-left: 20px;
                width: 300px;
                // border: 1px solid red;
                // flex-grow:2
            }

            .text{
                margin-left: 20px;
                width: 115px;
                color: #808080;
                // border: 1px solid red;
                // text-align: right;
                // flex-grow:1
            }
        }

        .sub_dimension{
            // margin-left: 5px;
            margin-top: 15px;
            display: flex;
            align-items: center;

            .title{
                width: 345px;
                margin-left: 10px;
                font-size: 30px;
                font-weight: 400;
                color: #808080;
                line-height: 1.5;
                flex-grow:1
            }

            .start{
                margin-left: 40px;
            }
        }

        .see_more{
            display: flex;
            // justify-content: center;
            align-items: center;
            margin-top: 20px;
            color: #929293;
            .open_or_close{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 60%;
                // border: 1px solid red;
            }

            .arrow-down{
                margin-left: 10px;
            }
        }

        .clear{
            display: flex;
            align-items: center;
            margin-left: 110px;
        }
    }
</style>
