<!--
 * @Author: steven 1105624290@qq.com
 * @Date: 2022-06-06 10:15:03
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2022-07-15 09:58:20
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/addSelect.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <van-dialog
        v-model="showAddLabel"
        @close="cancal"
        :title="title"
        class="add_select_dialog"
        showCancelButton
        :confirmButtonColor="'#4FB9B8'"
        :cancelButtonColor="'#CCCCCC'"
        @confirm="subMit1(checkedArr,index)"
    >
        <div class="content">
            <div class="list">
                <van-checkbox-group @change="handleCheck" v-model="checkedArr" ref="checkboxGroup">
                    <div class="item" v-for="(item,index) in selectList" :key="index">
                        <van-checkbox :ref="'checkboxRefs' + item.placeId" :name="item" class="checked" checked-color="#4FB9B8"></van-checkbox>
                        <div class="test">{{item.placeName}}</div>
                    </div>
                </van-checkbox-group>
            </div>
        </div>
    </van-dialog>
</template>
<script>
import { queryActivityPlaceApi, queryActivityTypeApi, queryActivityAdultParticipationApi,teacherRoleApi } from "@/api/login/index.js";
export default {
    props:["subMit1"],
    data (){
        return {
            checkedArr:[],
            selectList:[],
            showAddLabel:false,
            nowData:[],
            index:"",
            title:""
        }

    },
    methods:{
        //取消按钮
        cancal(){
            this.showAddLabel = false;
        },
        handleCheck(){
            console.log(this.checkedArr)
        },
        addSelLab(data,index,language){
            console.log(data,index,language)
            this.nowData = data;
            // this.showAddLabel = true;
            this.index = index;
            this.checkedArr = [];
            if(index === 1){
                this.title = "新增活动开展区域推荐"
                this.queryActivityPlace({language:language});
            }else if (index === 2){
                this.title = "新增活动类型"
                this.queryActivityType({language:language});
            }else if (index === 3){
                this.title = "新增成人参与"
                this.queryActivityAdultParticipation({language:language});
            } else if (index === 4){
                this.title = "新增教育者形象";
                this.queryTeacherRole({language:language});
            }
        },
        //活动区域
        queryActivityPlace(language){
            queryActivityPlaceApi(language).then(res => {
                this.selectList = res.data.map(item => {
                    return {
                        placeId:item.placeId,
                        placeName:item.placeName
                    }
                })
                this.nowData.forEach((element) => {
                    this.checkedArr.push(this.selectList.find((item) => item.placeId === element.placeId));
                })
                console.log("活动区域数据",this.selectList)
                this.showAddLabel = true;
            })
        },
        // 活动类型
        queryActivityType(language){
            queryActivityTypeApi(language).then(res => {
                this.selectList = res.data.map(item => {
                    return {
                        typeId:item.typeId,
                        typeName:item.typeName,
                        placeId:item.typeId,
                        placeName:item.typeName
                    }
                })
                this.nowData.forEach((element) => {
                    this.checkedArr.push(this.selectList.find((item) => item.typeId === element.typeId));
                })
                console.log("活动类型数据",this.selectList)
                this.showAddLabel = true;
            })
        },
        //成人参与
        queryActivityAdultParticipation(language){
            queryActivityAdultParticipationApi(language).then(res => {
                this.selectList = res.data.map(item => {
                    return {
                        adultId:item.adultId,
                        adultName:item.adultName,
                        placeId:item.adultId,
                        placeName:item.adultName
                    }
                })
                this.nowData.forEach((element) => {
                    this.checkedArr.push(this.selectList.find((item) => item.adultId === element.adultId));
                })
                console.log("成人参与数据",this.selectList)
                this.showAddLabel = true;
            })
        },
        //教师角色接口
        queryTeacherRole(language){
            teacherRoleApi(language).then(res => {
                this.selectList = res.rows.map(item => {
                return {
                    roleName:item.roleName,
                    roleId:item.roleId,
                    placeId:item.roleId,
                    placeName:item.roleName
                }
                });
                this.nowData.forEach((element) => {
                    this.checkedArr.push(this.selectList.find((item) => item.roleId === element.roleId));
                })
                console.log("教师角色数据",this.selectList)
                this.showAddLabel = true;
            })
        },
    },
    mounted() {},
}
</script>
<style lang="scss" scoped>
    .add_select_dialog{
        width: 700px;
        top: -45%;
        position: relative;
        
        /deep/.van-dialog__header{
            font-weight: bold;
            padding-top: 26px;
        }
        .content{
            .list{
                height: 740px;
                overflow: hidden;
                overflow-y: scroll;
                padding-left: 30px;
                padding-top: 20px;
                .checked1{
                    margin-bottom: 40px;
                    span{
                        margin-left: 30px;
                    }
                }

                .item{
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;
                    .img{
                        width: 90px;
                        height: 90px;
                        border-radius: 50%;
                        margin-left: 30px;
                    }

                    .test{
                        margin-left: 30px;
                        font-size: 30px;
                        color: #606060;
                        width: 600px;
                    }
                }
            }
        }
    }
</style>
