<!--
 * @Author: your name
 * @Date: 2021-11-28 14:22:23
 * @LastEditTime: 2023-02-17 18:56:02
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/resources.vue
-->
<template>
    <div class="text resources_item">
        <div class="every" v-for="(item,index) in data" :key="index">
            <template v-if="isShowMore || index<3">
                <div class="top">
                    <div class="resouce_img" v-if="item.fileType === '0'">
                        <img :src="item.fileUrl + '?x-oss-process=image/resize,h_200,w_250'" :data-large="item.fileUrl" v-gallery alt="" class="resources-img" v-if="item.fileType === '0'">
                        <div class="a" v-if="isDown == 0"></div>
                    </div>
                    <div class="video_box" v-if="item.fileType === '1'" @click="showBig(item)">
                        <video :poster="item.fileUrl+'?x-oss-process=video/snapshot,t_50,f_jpg,w_400,h_350'" :src="item.fileUrl" v-if="item.fileType === '1'" class="video-size"></video>
                        <div class="video-bg">
                            <img src="@/assets/img/playIcon.png" class="uploadResourceImg"/>
                        </div>
                    </div>
                    <a v-if="item.fileType === '2' && isDown != 0" :href="item.fileUrl" class="left-icon">
                        <img src="@/assets/img/file.png" class="resources-img" alt="">
                    </a>
                    <img src="@/assets/img/file.png" class="resources-img" alt="" v-if="item.fileType === '2' && isDown == 0" @click="seePDFDialog(item)">
                    <img @click="toLink(item)" src="@/assets/img/link.png" class="resources-img" v-if="item.fileType == 3" alt="">
                    <img @click="showBig(item)" v-if="item.fileType == 4" class="resources-img" src="@/assets/img/radio.png"/>
                </div>
                <div class="bottom">{{item.fileName}}</div>
            </template>
        </div>
        <div class="see_more" v-show="data.length > 3" @click="showMore">
            <span>{{ isShowMore ? "收起" : "展开" }}</span>
            <van-icon :name="isShowMore?'arrow-up' : 'arrow-down'" />
        </div>
        <seePDF ref="seePDF" :pdfInfo="pdfInfo"></seePDF>
    </div>
</template>

<script>
import seePDF from "@/components/seePDF/index";
export default {
    props:["data","isDown"],
    components:{ seePDF },
    data() {
        return {
            isShowMore:false,
            pdfInfo: {
                url: "",
                name: "",
            }
        }
    },
    methods:{
        seePDFDialog(item){
            console.log(item)
            this.pdfInfo.name = item.fileName;
            this.pdfInfo.url = item.fileUrl;
            this.$refs.seePDF.show = true;
        },
        showMore(){
            this.isShowMore = !this.isShowMore;
        },
        //弹框显示资源
        showBig(item){
            this.$emit("sendResources",item)
        },
        toLink(item){
            window.open(item.fileUrl,'_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
    .resources_item{
        width: 100%;
        display: flex;
        flex-wrap:wrap;
        // justify-content: space-between;
        .every{
            width: 222px;
            text-align: center;
            margin-bottom: 10px;
            // border: 1px solid red;
            margin-right: 12px;

            .top{
                width: 222px;
                height: 163px;
                // border: 1px solid red;

                .resources-img{
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
                .resouce_img{
                    width: 100%;
                    height: 100%;
                    // border: 1px solid red;
                    position: relative;

                    .a{
                        width: 100%;
                        height: 100%;
                        background: black;
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .video_box{
                    position: relative;
                    width: 222px;
                    height: 163px;
                    .video-size{
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                        // border: 1px solid red;
                    }
                    .video-bg{
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.39) !important;
                        position: absolute;
                        left: 0;
                        top: 0;
                        .uploadResourceImg{
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }

            .bottom{
                margin-top: 29px;
                font-size: 28px;
                color: #929293;
                word-wrap:break-word ;
            }
        }

        .every:nth-child(3n){
            margin-right: 0;
        }

        .see_more{
            width: 100%;
            text-align: center;
            font-size: 26px;
        }
    }
</style>

