<!--
 * @Author: your name
 * @Date: 2022-04-13 17:18:36
 * @LastEditTime: 2022-06-07 11:14:28
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/addLabel.vue
-->
<template>
    <van-dialog
        v-model="showAddLabel"
        @close="cancal"
        :title="'新增学生标签'"
        class="add_label_dialog"
        showCancelButton
        :confirmButtonColor="'#4FB9B8'"
        :cancelButtonColor="'#CCCCCC'"
        @confirm="subMit(cloneCheckedArr,index)"
    >
    
    <div class="content">
        <van-search :left-icon="''" :right-icon="'van-icon van-icon-search'" v-model="value" shape="round" placeholder="请输入搜索关键词"/>
        <div class="list">
            <van-checkbox v-model="checkedAll" @click="getAll" class="checked1" checked-color="#4FB9B8">
                <span>全选</span>
            </van-checkbox>
            <van-checkbox-group @change="handleCheck" v-model="checkedArr" ref="checkboxGroup">
                <div class="item" v-for="(item,index) in studentList" :key="index">
                    <van-checkbox :ref="'checkboxRefs' + item.studentId" :name="item" class="checked" checked-color="#4FB9B8"></van-checkbox>
                    <img class="img" :src="pictureFuc(item)" alt="">
                    <div class="test">{{item.nameCn}}</div>
                </div>
            </van-checkbox-group>
        </div>
    </div>
    </van-dialog>
</template>
<script>
import { parkClassesHomeTotalApi } from "@/api/login/index.js";
const gitlPic = require("@/assets/img/profile-girl.jpg");
const boyPic = require("@/assets/img/profile-boy.jpg");
export default {
    props:["subMit"],
    data() {
        return {
            showAddLabel:false,
            value:"",
            studentList:[],
            otableData:[],
            checkedArr:[],//选中的数据
            index:"",
            checkedAll:false, // 全选状态
            cloneCheckedArr:[],//深拷贝的点击全选的数据
        }
    },
    methods:{
        // 全选
        getAll(){
            this.checkedArr = this.checkedAll ? this.otableData : []
            this.cloneCheckedArr = this._.cloneDeep(this.checkedArr)
        },
        handleCheck(){
            this.cloneCheckedArr = this._.cloneDeep(this.checkedArr)
            this.checkedAll = this.checkedArr.length === this.otableData.length
        },
        //头像处理
        pictureFuc(row) {
            if (row.avatar) {
                return row.avatar + "?x-oss-process=image/resize,h_90,w_90";
            } else {
                if (row.sex === "0") {
                return gitlPic;
                } else {
                return boyPic;
                }
            }
        },
        //弹框出现
        addStuLabel(data,index){
            console.log(data,index);
            this.value = "";
            this.studentList = this.otableData;
            console.log("aaaaa",this.studentList)
            this.index = index;
            this.showAddLabel = true;
            this.checkedArr = [];
            data.forEach((element) => {
                this.checkedArr.push(this.studentList.find((item) => item.studentId === element.studentId));
            })
            if(data.length === this.studentList.length){
                this.checkedAll = true
            }else{
                this.checkedAll = false
            }
            
        },
        //取消按钮
        cancal(){
            this.showAddLabel = false;
        },
        // 获取上传档案的学生下拉接口
        queryParkClassesHomeTotal(){
            parkClassesHomeTotalApi(this.$route.params.classesId,this.$route.params.schoolYearId).then(res => {
                this.studentList = res.data.map((item) => {
                    return {
                    flag: 1,
                    studentId: item.id,
                    id: null,
                    nameCn: item.nameCn,
                    avatar: item.avatar,
                    studentName:item.nameCn,
                    sex:item.sex
                    };
                });
                this.otableData = this.studentList;
                
                console.log("上传档案学生下拉",this.studentList)
                this.$emit("studentList",this.studentList)
                // this.$store.commit("studentList",this.studentList)
            })
        },
    },
    watch: {
        value(val) {
        if (!val.trim()) {
            this.studentList = this.otableData; // eslint-disable-line no-unused-vars
        } else {
            this.studentList = this.otableData.filter((item) => {
                return (~item.studentName.indexOf(val));
            });
        }
        },
    },
    mounted() {
        console.log(this.$route.params.classesId,this.$route.params.schoolYearId);
        // console.log("存储底部上传的数据",this.$store.state.ArchivesData)
        this.queryParkClassesHomeTotal()
    },
}
</script>
<style lang="scss" scoped>
    .add_label_dialog{
        width: 700px;
        // position: absolute;
        // top: 55%;
        top: -45%;
        position: relative;
        
        /deep/.van-dialog__header{
            font-weight: bold;
            padding-top: 26px;
        }
        .content{
            .list{
                height: 740px;
                overflow: hidden;
                overflow-y: scroll;
                // border: 1px solid red;
                padding-left: 30px;
                .checked1{
                    margin-bottom: 40px;
                    span{
                        margin-left: 30px;
                    }
                }

                .item{
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;
                    .img{
                        width: 90px;
                        height: 90px;
                        border-radius: 50%;
                        margin-left: 30px;
                    }

                    .test{
                        margin-left: 30px;
                        font-size: 30px;
                        color: #606060;
                    }
                }
            }
        }
    }
</style>
