<!--
 * @Author: steven 1105624290@qq.com
 * @Date: 2023-02-17 15:59:48
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2023-02-17 18:54:32
 * @FilePath: /ytjj-mobile/src/components/seePDF/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <van-dialog v-model="show" title="预览" :showConfirmButton="false" class="pdf_dialog">
        <van-icon @click="close" name="cross" class="cancal" />
        <pdf class="pdf" :rotate="pageRotate" v-for="i in numPages" :key="i" :src="src" :page="i" style="width: 100%;height:100% "></pdf>
    </van-dialog>
</template>

<script>
import pdf from 'vue-pdf';

export default {
    components: {
        pdf
    },
    data () {
        return {
            show:false,
            numPages: [],
            src: "",
            currentPage: 0,
            pageCount: 0,
            pageRotate:0
        }
    },
    props:["pdfInfo"],
    watch: {
        show(val) {
            if (val) {
                this.src = pdf.createLoadingTask({url:this.pdfInfo.url});
                console.log(this.pdfInfo.url);
                this.src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                }).catch((err) => {
                    console.error("失败",err)
                })                       
            }
        }
    },
    methods:{
        close(){
            this.show = false;
        },
    }
}
</script>

<style lang="scss" scoped>
    .pdf_dialog{
        height: 82%;
        overflow: auto;
        top: 50%;
        width: 90%;

        /deep/.van-dialog__header{
            font-size: 34px;
            padding-top: 26px;
        }
        .cancal{
            position: absolute;
            right: 20px;
            top: 20px;
        }
        /deep/.van-dialog__content{
            text-align: center;
        }    
        /deep/ .van-dialog__content{
            height:calc(100% - 70px);
        }
    }
</style>
