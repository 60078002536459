<!--
 * @Author: your name
 * @Date: 2022-03-21 13:59:07
 * @LastEditTime: 2022-06-10 10:44:15
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/components/popup/knowledgePop.vue
-->
<template>
    <div>
        <van-popup :close-on-click-overlay="false" v-model="show" @click-overlay="chosoe"  class="knowledge_pop">
            <div class="con_box">
                <div class="title">{{title}}</div>
                <div class="content">
                    <div class="top">
                            <van-field :disabled="isEdit" required readonly v-model="dataObj.contentThatCanBeIntroducedTp.mainAreas" @focus="ActionSheet = true" label="学科" placeholder="请选择学科" />
                            <van-action-sheet v-model="ActionSheet" :actions="actions" @select="onSelect" />
                            <van-icon @click="showActionSheet" class="arrow-down" name="arrow-down" />
                            <!-- @click="ActionSheet = true" -->
                    </div>
                    <div class="bottom">
                        <!-- <van-field required v-model="dataObj.content" rows="4" label="内容" type="textarea" placeholder="请输入内容" /> -->
                        <div class="name_title">
                            <span style="color:#ee0a24">* </span>
                            <span class="text_name">内容</span>
                        </div>
                        <div class="item_content">
                            <div class="line_item" v-show="item.flag == '1'" v-for="(item,index) in dataObj.contentThatCanBeIntroducedTpDetails" :key="index">
                                <van-field v-model="item.content" type="input" placeholder="请输入内容" />
                                <van-icon @click="delectItem(item,index)" name="close" />
                            </div>
                        </div>
                        
                    </div>
                    <div class="add_line">
                        <van-button icon="plus" class="add" @click="addNew">新增</van-button>
                    </div>
                </div>
                <div class="btn">
                    <van-button @click="handleClose()" class="no_save" >取消</van-button>
                    <van-button @click="handleOk()" class="save">保存</van-button>
                </div>
            </div>
        </van-popup>
        <!-- 删除知识点推荐提示 -->
        <van-popup class="pop" v-model="isShowPop">
            <div class="con_box1">
            <div class="title">{{text.title}}</div>
            <p>{{text.message}}</p>
            <div class="btn">
                <van-button @click="noSave()" class="no_save" >{{text.btn.cancelText}}</van-button>
                <van-button @click="Save()" class="save" >{{text.btn.okText}}</van-button>
            </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
// import { getIntroducedListApi} from "@/api/login/index.js";
import { queryContentThatCanBeIntroducedTpApi} from "@/api/login/index.js";
export default {
    props:["detailsData"],
    data() {
        return {
            show:false,
            title:"",
            ActionSheet:false,
            actions:[], // 下拉数据
            dataObj:{
                contentThatCanBeIntroducedTp:{},
                contentThatCanBeIntroducedTpDetails:[]
            },
            text: {
                title: '提示',
                message: '确定删除当前行吗？',
                btn: {
                okText: '确定',
                cancelText: '取消'
                }
            },
            isShowPop:false, //删除弹框的出现隐藏
            delectData:{}, //点击删除按钮传过来的数据
            isEdit:false,// 是否是编辑知识点推荐
            allKnowledgeData:[],//这是当前所有的知识点推荐数据的下拉id让新增时候不能新增相同学科
        }
    },
    methods:{
        chosoe(){
            this.ActionSheet = false;
        },
        // 右边下拉箭头展开
        showActionSheet(){
            if(!this.isEdit){
                this.ActionSheet = true
            }
        },
        // 新增时候获取所有的知识点推荐
        getAllKnowledgeData(dataArr){
            this.allKnowledgeData = []
            dataArr.forEach(item => {
                this.allKnowledgeData.push(item.contentThatCanBeIntroducedTp.mainAreasId);
            })
            this.actions.forEach(item => {
                if(this.allKnowledgeData.indexOf(item.mainAreasId)!= -1){
                    item.disabled = true;
                }else{
                    item.disabled = false;
                }
            })
        },
        // 删除某个
        delectItem(item,index){
            console.log(item,index);
            item.id ? item.flag = "0" : this.dataObj.contentThatCanBeIntroducedTpDetails.splice(index,1)
        },
        // 新增行
        addNew(){
            let obj = {
                content:"",
                flag:"1"
            }
            this.dataObj.contentThatCanBeIntroducedTpDetails.push(obj);
        },
        // 删除数据时候取消
        noSave(){
            this.isShowPop = false;
        },
        //删除时候保存
        Save(){
            this.$emit("delectKnowledge",this.delectData)
            this.isShowPop = false;
        },
        // 选中
        onSelect(item){
            this.dataObj.contentThatCanBeIntroducedTp.mainAreas = item.name;
            this.dataObj.contentThatCanBeIntroducedTp.mainAreasId = item.mainAreasId;
            this.ActionSheet = false;
        },
        //取消
        handleClose(){
            this.show = false;
        },
        //保存
        handleOk(){
            console.log(this.dataObj)
            if(!this.dataObj.contentThatCanBeIntroducedTp.mainAreas){
                this.$notify( {type: 'danger', message: '学科不能为空' });
                return;
            }
            if(!this.dataObj.contentThatCanBeIntroducedTpDetails.length){
                this.$notify( {type: 'danger', message: '至少填写一行内容' });
                return;
            }
            let flag = this.dataObj.contentThatCanBeIntroducedTpDetails.every((item) => {
                return item.flag == "0";
            })
            if(flag){
                this.$notify( {type: 'danger', message: '至少填写一行内容' });
                return;
            }
            let arr = [];
            this.dataObj.contentThatCanBeIntroducedTpDetails.forEach(item => {
                if(item.flag == "1"){
                    arr.push(item);
                }
            })
            console.log("新的数组",arr);
            for (let i =0; i< arr.length;i++){
                for (let j =i+1; j<arr.length;j++){
                    if(arr[i].content === arr[j].content){
                        this.$notify( {type: 'danger', message: '内容不能重复' });
                        return;
                    }
                }
            }
            for(let i = 0;i<this.dataObj.contentThatCanBeIntroducedTpDetails.length;i++){
                let item = this.dataObj.contentThatCanBeIntroducedTpDetails[i];
                if(!item.content){
                    this.$notify( {type: 'danger', message: '内容不能为空' });
                    return;
                }
            }
            this.dataObj.isEdit = this.isEdit;
            this.$emit("saveKnowledge",this.dataObj)
            this.show = false;
        },
        //获取学科接口
        getIntroducedList(){
            // getIntroducedListApi(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language).then(res => {
            //     // console.log(res)
            //     this.actions = res.data;
            //     this.actions.forEach(item => {
            //         item.name = item.mainAreas;
            //     })
            // })
            let obj = {
                grade:this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.grade,
                language:this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language
            }
            if(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.grade == 0) {
                obj.grade = "0"
            }else {
                obj.grade = "1"
            }
            queryContentThatCanBeIntroducedTpApi(obj).then(res => {
                this.actions = res.data;
                this.actions.forEach(item => {
                    item.name = item.mainAreas;
                })
            })
        }
    },
    mounted() {
        this.getIntroducedList();
    },
}
</script>
<style lang="scss" scoped>
    .knowledge_pop {
        border-radius: 20px;
        .con_box{
            // width: 661px;
            width: 720px;
            // min-height: 620px;
            line-height: 1;
            color: #4d5c82;
            padding:43px 34px 0  34px;
            box-sizing: border-box;
            .title {
                font-size: 40px;
                // margin-top: 10px;
                margin-bottom: 49px;
                text-align: center;   
                color: #1E1E1E;
                line-height: 53px;
                font-weight: bold;
            }
            .content{
                /deep/.van-field__label{
                    width: 70px;
                }
                /deep/.van-field__value {
                    border:1px solid #dfe6ec;
                }
                /deep/.van-field__control{
                    padding-left: 5px;
                }
                /deep/.van-cell::after {
                    border: none;
                }
                
                .top {
                    position: relative;
                    /deep/.van-field__label{
                        font-size: 34px;
                    }
                    /deep/.van-popup--bottom.van-popup--round {
                        border-radius: 20px;
                    }
                    /deep/.van-overlay{
                        border-radius: 20px;
                    }
                    /deep/.van-action-sheet {
                        // max-height: 100%;
                        min-height: 100%;
                    }

                    .arrow-down{
                        position: absolute;
                        top: 33px;
                        right: 40px;
                        color: #969799;
                    }
                }
                .bottom {
                    display: flex;
                    // align-items: center;
                    .name_title{
                        padding: 23px 0 10px 16px;
                        width: 120px;
                        .text_name{
                            font-size: 34px;
                            color: #4FB9B8;
                        }
                    }
                    /deep/.item_content{
                        .line_item{
                            display: flex;
                            align-items: center;
                            .van-cell {
                                padding-left: 8px;
                                width: 460px;
                            }
                            .van-icon-close{
                                font-size: 50px;
                                color: #4FB9B8;
                            }
                        }
                        
                    }
                }
                .add_line{
                    display: flex;
                    flex-direction:row-reverse;
                    .add{
                        width: 171px;
                        border-radius: 15px;
                        background-color: #4DB3B3;
                        color: white;
                    }
                }
                /deep/.van-field__label{
                    font-size: 34px;
                    color: #4FB9B8;
                }
                /deep/.van-field__control{
                    font-size: 34px;
                }
            }
            .btn {
                font-size: 30px;
                margin-top: 20px;
                margin-bottom: 35px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .no_save{
                    width: 291px;
                    border-radius: 15px;
                    border: 3px solid #4DB3B3;
                    color: #4FB9B8;
                }

                .save{
                    width: 291px;
                    border-radius: 15px;
                    background-color: #4DB3B3;
                    color: white;
                }
            }
        }
    }
    .con_box1 {
      width: 661px;
      height: 446px;
      line-height: 1;
      color: #4d5c82;
      padding:43px 34px 0  34px;
      box-sizing: border-box;
      .title {
      font-size: 40px;
      // margin-top: 10px;
      margin-bottom: 49px;
      text-align: center;   
      color: #1E1E1E;
      line-height: 53px;
      font-weight: bold;
      }
      > p {
      font-size: 36px;
      padding-left: 7px;
      color: #3F3F3F;
      }
      .btn {
          font-size: 30px;
          margin-top: 140px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .no_save{
              width: 291px;
              border-radius: 15px;
              border: 3px solid #4DB3B3;
              color: #4FB9B8;
          }

          .save{
              width: 291px;
              border-radius: 15px;
              background-color: #4DB3B3;
              color: white;
          }
      > span {
          display: block;
          width: 114px;
          background-color: #e0e5f5;
          text-align: center;
          line-height: 44px;
          font-size: 25px;
          margin-left: 30px;
      }
      > span:last-of-type {
          background-color: #1288fe;
          color: #ffffff;
      }
      }
  }
  .pop {
      border-radius: 20px;
  }
</style>