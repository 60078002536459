<!--
 * @Author: your name
 * @Date: 2021-11-26 13:14:09
 * @LastEditTime: 2022-03-14 11:03:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/watchDialog.vue
-->
<template>
  <van-dialog
    v-model="show"
    :confirm-button-text="buttonText"
    @close="cancal"
    @confirm="download"
    :title="data.fileName"
    :showCancelButton="showCancelButton"
    class="showBigResources"
  >
    <div class="content">
      <!-- <div class="img" v-if="data.fileType === '0'">
        <img
          :src="data.fileUrl"
          alt=""
          class="big_img"
          v-if="data.fileType === '0'"
        />
      </div>
      <div class="video" v-if="data.fileType === '1'">
        <video
          controls
          autoplay
          :src="data.fileUrl"
          v-if="data.fileType === '1'"
          class="video"
          webkit-playsinline
          playsinline
          x5-video-player-type="h5"
          muted="muted"
          ref="videoStop"
          id="videoNo"
        ></video>
      </div>
      <audio
        class="audio"
        v-if="data.fileType === '4'"
        :src="data.fileUrl"
        controls
        autoplay
      ></audio> -->
      <div class="video_tag" v-if="data.fileType === '1'">
        <video
          controls
          autoplay
          :src="data.fileUrl"
          v-if="data.fileType === '1'"
          class="video"
          webkit-playsinline
          playsinline
          x5-video-player-type="h5"
          muted="muted"
          ref="videoStop"
          id="videoNo"
        ></video>
        <div class="lyric" v-if="data.lyric">
          <pre class="lyric_text" v-html="data.lyric"></pre>
        </div>
      </div>
      <div class="audio_tag" v-if="data.fileType === '4'">
        <div class="lyric" v-if="data.lyric">
          <pre class="lyric_text" v-html="data.lyric"></pre>
        </div>
        <div class="no_lyric" v-else>
          <img src="@/assets/img/Layer 46@2x.png" alt="">
        </div>
        <audio
          class="audio"
          v-if="data.fileType === '4'"
          :src="data.fileUrl"
          controls
          autoplay
        ></audio>
      </div>
    </div>
  </van-dialog>
</template>
<script>
// import { saveAs } from "file-saver";
// import { downloadIamge } from "@/utils/utils.js";
export default {
  data() {
    return {
      show: false,
      data: {},
      showCancelButton:false,
      buttonText:"确认"
    };
  },
  methods: {
    stopVideo(){
      if(this.$refs.videoStop.paused){
        this.$refs.videoStop.play();
      }else{
        this.$refs.videoStop.pause();
      }
    },
    showDialog(type,item) {
      if(type === "archives"){
        this.showCancelButton = false
        this.buttonText="关闭"
      }else{
        this.showCancelButton = false
        this.buttonText="关闭"
      }
      this.show = true;
      this.data = item;
      console.log("传到弹框的参数", item);
    },
    // 关闭弹框
    cancal() {
      if(this.data.fileType==="1"){
        this.$refs.videoStop.pause();
        this.$refs.videoStop.removeAttribute('src'); // empty source
        this.$refs.videoStop.load();
        this.$refs.videoStop.remove();
        this.data = {};
      }
      this.data = {};
    },
    //点击下载
    download(){
      console.log("2222")
      if(this.data.fileType==="1"){
        this.$refs.videoStop.pause();
        this.$refs.videoStop.removeAttribute('src'); // empty source
        this.$refs.videoStop.load();
        this.$refs.videoStop.remove();
        this.data = {};
      }
      this.data = {};
    },
  },
  watch:{
    show(val){
      if(val){
        if(this.data.fileType==="1"){
          setTimeout(() => {
            this.$refs.videoStop.play();
            this.$refs.videoStop.muted = false;
            // this.$refs.videoStop.currentTime = 0;
            // this.$refs.videoStop.seek(0);
          }, 1);
        }
      }
    }
  },
  mounted() {
    // var video=document.getElementById("videoNo"); 
    // video.play();
    //  this.$refs.videoStop.play();
    console.log("sakdksaj",this.$refs.videoStop)
  },
};
</script>
<style lang="scss" scoped>
.showBigResources {
  .content {
    width: 100%;
    min-height: 350px;
    // border: 1px solid red;
    box-sizing: border-box;
    padding: 20px 20px;
    text-align: center;
    display: flex;
    align-items: center;

    .img {
      width: 100%;
      height: 100%;
    }

    .big_img {
      max-height: 100%;
      max-width: 100%;
    }

    .video_tag{
      width: 100%;
      height: 100%;

      video{
        width: 100%;
        height: 350px;
        object-fit: fill;
      }

      .lyric{
        height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        .lyric_text{
          width: 96%;
          height: 100%;
        }
      }
    }

    .audio_tag{
      width: 100%;
      height: 100%;

      .lyric{
        height: 570px;
        overflow-y: scroll;

        .lyric_text{
          height: 100%;
          width: 96%;
        }
      }
      .no_lyric{
        height: 450px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-width: 80%;
          max-height: 300px;
        }
      }

      .audio{
        width: 100%;
        margin-top: 20px;
      }
    }

    pre {
        white-space: pre-wrap;       /* CSS-3 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        Word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }
  }
  /deep/.van-button{
    color:#44b1b6 !important;
  }
}
</style>

