<!--
 * @Author: your name
 * @Date: 2021-11-29 16:36:05
 * @LastEditTime: 2021-12-20 09:45:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/components/popup/index.vue
-->
<template>
  <van-popup class="pop" v-model="isShow">
    <div class="con_box">
      <div class="title">{{text.title}}</div>
      <p>{{text.message}}</p>
      <div class="btn">
        <!-- <span @click="handleClose()" v-if="text.btn.cancelText">{{text.btn.cancelText}}</span>
        <span @click="handleOk()" v-if="text.btn.okText">{{text.btn.okText}}</span> -->
        <van-button @click="handleClose()" v-if="text.btn.cancelText" class="no_save" >{{text.btn.cancelText}}</van-button>
        <van-button @click="handleOk()" class="save" v-if="text.btn.okText">{{text.btn.okText}}</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      text: {
        title: '提示',
        message: '确定删除当前资源吗？',
        btn: {
          okText: '确定',
          cancelText: '取消'
        }
      },
      data:{}
    }
  },
  watch:{
      isShow(val){
          if(val){
              console.log(this.data)
          }
      }
  },
  methods: {
    handleClose() {
      console.log('关闭')
      this.isShow = false;
    },
    handleOk() {
      console.log('确定')
      this.data.flag = "0";
      this.isShow = false;
      this.$emit("delect",this.data)
    }
  }
}
</script>
<style lang='scss' scoped>
.van-popup {
  border-radius: 10px;
  .con_box {
      width: 661px;
      height: 446px;
      line-height: 1;
      color: #4d5c82;
      padding:43px 34px 0  34px;
      box-sizing: border-box;
      .title {
      font-size: 40px;
      // margin-top: 10px;
      margin-bottom: 49px;
      text-align: center;   
      color: #1E1E1E;
      line-height: 53px;
      font-weight: bold;
      }
      > p {
      font-size: 36px;
      padding-left: 7px;
      color: #3F3F3F;
      }
      .btn {
          font-size: 30px;
          margin-top: 140px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .no_save{
              width: 291px;
              border-radius: 15px;
              border: 3px solid #4DB3B3;
              color: #4FB9B8;
          }

          .save{
              width: 291px;
              border-radius: 15px;
              background-color: #4DB3B3;
              color: white;
          }
      > span {
          display: block;
          width: 114px;
          background-color: #e0e5f5;
          text-align: center;
          line-height: 44px;
          font-size: 25px;
          margin-left: 30px;
      }
      > span:last-of-type {
          background-color: #1288fe;
          color: #ffffff;
      }
      }
  }
}
</style>
