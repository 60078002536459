<!--
 * @Author: your name
 * @Date: 2021-11-19 11:16:59
 * @LastEditTime: 2023-02-17 13:11:15
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/index.vue
-->
<template>
    <div class="lesson_plan_details">
        <div class="header">
            <div class="plan_id">
                <van-icon @click="goback" class="icon_top" name="arrow-left" />
                <div>教案{{detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.number}}</div>
                <!-- <van-icon @click="showLogoOut" class="icon_top" name="ellipsis" /> -->
                <img @click="showLogoOut" class="icon_top_right" src="@/assets/pd/choose.png" alt="">
            </div>
        </div>
        <div class="body">
            <van-loading class="loading" v-if="loading" />
            <div class="boday_content" v-else>
                <div class="bodoy_header">
                    <div class="head_portrait">
                        <headPortrait :showSelect="false" />
                    </div>
                    <div class="header_text">
                        <div class="title">教学时间</div>
                        <div class="content">
                            <div class="time_item"  v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerDateDetailVos" :key="index">
                                <template v-if="handleQueryShow || index<1">
                                    <div class="year_time">
                                        {{item.lessonPlannerDate.classTime}} {{'周'+'日一二三四五六'.charAt(new Date(item.lessonPlannerDate.classTime).getDay())}}
                                    </div>
                                    <div class="clock_time">
                                        <div class="clock_time_item" v-for="(item1,index1) in item.lessonPlannerDateDetails" :key="index1">
                                            <div class="clock_time_item_index" :class="$moment().format('YYYY-MM-DD') === item.lessonPlannerDate.classTime?'is_today':''" v-if="handleQueryShow || index1<2">
                                                {{item1.startTime}} - {{item1.endTime}}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="see_more" v-show="isShowMore > 2 || detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerDateDetailVos.length > 1" @click="showMore">
                        <span>{{ handleQueryShow ? "收起" : "更多" }}</span>
                        <van-icon :name="handleQueryShow?'arrow-up' : 'arrow-down'" />
                    </div>
                </div>
                <div class="item resources" v-if="detailsData.lessonPlannerAndActivityDetailVo.templateLessonResources.length">
                    <div class="title">资源</div>
                    <div class="text resources_item" :class="detailsData.lessonPlannerAndActivityDetailVo.isPermissionDownload == 0 ?'is_down':''">
                        <resources @sendResources="sendResources" :isDown="detailsData.lessonPlannerAndActivityDetailVo.isPermissionDownload" :data="detailsData.lessonPlannerAndActivityDetailVo.templateLessonResources"></resources>
                    </div>
                </div>


                <div class="item">
                    <div class="title">
                        <span class="must_logo" v-if="isDetails">*</span>
                        模版探究问题
                    </div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.inquiryQuestion.name"></pre>
                    </div>
                    <div class="concept" v-if="isDetails">
                        <van-field disabled v-model="detailsData.inquiryQuestion.name" type="input" placeholder="请输入内容" />
                    </div>
                </div>
                <div class="item" v-if="cloneOriginalInquiryQuestion">
                    <div class="title">
                        <span class="must_logo" v-if="isDetails">*</span>
                        原创探究问题
                    </div>
                    <div class="text" v-if="!isDetails">
                        <pre style="white-space: pre-wrap;" class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.originalInquiryQuestion"></pre>
                    </div>
                    <div class="concept" v-if="isDetails">
                        <van-field autosize v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.originalInquiryQuestion" type="textarea" placeholder="请输入内容" />
                    </div>
                </div>

                
                <div class="item">
                    <div class="title">
                        <span class="must_logo" v-if="isDetails">*</span>
                        活动名称
                    </div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor active_name" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.name"></pre>
                    </div>
                    <div v-if="isDetails">
                        <!-- quill.getSelection().index -->
                        <quillEditor @change="changeText($event)" :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.name"></quillEditor>
                    </div>  
                </div>
                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.concept,isDetails)">
                    <div class="title">
                        概念
                    </div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.concept"></pre>
                    </div>
                    <div class="concept" v-if="isDetails">
                        <van-field v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.concept" type="input" placeholder="请输入内容" />
                    </div>
                </div>
                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.suggestedTime,isDetails)">
                    <div class="title">建议时间</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.suggestedTime"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.suggestedTime"></quillEditor>
                    </div>
                </div>
                <div class="item" v-if="grade=='0' && (detailsData.lessonPlannerAndActivityDetailVo.activityPlaces.length || isDetails)">
                    <div class="title">
                        活动开展区域推荐
                    </div>
                    <div class="text">
                        <div class="text_item" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.activityPlaces" :key="index">
                            {{item.placeName}}
                        </div>
                    </div>
                    <img @click="addSelectLabel(detailsData.lessonPlannerAndActivityDetailVo.activityPlaces,1,detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language)" src="@/assets/img/Layer add.png" alt="" class="add_img" v-if="isDetails">
                </div>
                <div class="item" v-if="grade=='0' && (detailsData.lessonPlannerAndActivityDetailVo.activityTypes.length || isDetails)">
                    <div class="title">
                        活动类型
                    </div>
                    <div class="text">
                        <div class="text_item" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.activityTypes" :key="index">
                            {{item.typeName}}
                        </div>
                    </div>
                    <img @click="addSelectLabel(detailsData.lessonPlannerAndActivityDetailVo.activityTypes,2,detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language)" src="@/assets/img/Layer add.png" alt="" class="add_img" v-if="isDetails">
                </div>
                <div class="item" v-if="grade=='0' && (detailsData.lessonPlannerAndActivityDetailVo.activityAdultParticipations.length || isDetails)">
                    <div class="title">
                        成人参与
                    </div>
                    <div class="text">
                        <div class="text_item" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.activityAdultParticipations" :key="index">
                            {{item.adultName}}
                        </div>
                    </div>
                    <img @click="addSelectLabel(detailsData.lessonPlannerAndActivityDetailVo.activityAdultParticipations,3,detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language)" src="@/assets/img/Layer add.png" alt="" class="add_img" v-if="isDetails">
                </div>
                <div class="item" v-if="detailsData.lessonPlannerAndActivityDetailVo.activityTeacherRoles.length || isDetails">
                    <div class="title">
                        <!-- <span class="must_logo" v-if="isDetails">*</span> -->
                        教育者形象
                    </div>
                    <div class="text">
                        <div class="text_item" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.activityTeacherRoles" :key="index">
                            {{item.roleName}}
                        </div>
                    </div>
                    <img @click="addSelectLabel(detailsData.lessonPlannerAndActivityDetailVo.activityTeacherRoles,4,detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language)" src="@/assets/img/Layer add.png" alt="" class="add_img" v-if="isDetails">
                </div>
                <!-- 托班学习与发展目标 -->
                <div class="item" v-if="(tobanLength || isDetails) && grade=='0'">
                    <div class="title">学习与发展目标</div>
                    <div class="text ovfl_width">
                        <div class="table_width">
                            <ve-table style="width:100%" :border-y="true" :columns="tobancolumns" :table-data="detailsData.lessonPlannerAndActivityDetailVo.learningDevelopmentGoalsTpYtjjs" />
                        </div>
                    </div>
                    <div class="no_data" v-if="!tobanLength">
                        暂无数据
                    </div>
                </div>

                <div class="item study" v-if="(studyArr.length || isDetails) && grade!='0'">
                    <div class="title">
                        <!-- <span class="must_logo" v-if="isDetails">*</span> -->
                        重点领域</div>
                    <div class="text">
                        <div class="table" v-if="studyArr.length">
                            <tableList :detailsData="detailsData"></tableList>
                        </div>
                        <div class="no_data" v-if="!studyArr.length">
                            暂无数据
                        </div>
                    </div>
                </div>
                <!-- 知识点推荐 -->
                <div class="item knowledge_table" v-if="detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos.length || isDetails">
                    <div class="title">{{knowPoint.title}}</div>
                    <div class="text knowledge">
                        <ve-table style="width:100%" :border-y="true" :columns="columns" :table-data="detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos" />
                    </div>
                    <div class="no_data" v-if="!detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos.length">
                        暂无数据
                    </div>
                    <!-- :scroll-width="400" -->
                    <div class="add_btn" v-if="isDetails">
                        <van-button class="add-line" @click="addknowledge">添加行</van-button>
                    </div>
                </div>
                
                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.areasOfFocus,isDetails)">
                    <div class="title">
                        <!-- <span class="must_logo" v-if="isDetails">*</span> -->
                        重点</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.areasOfFocus"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.areasOfFocus"></quillEditor>
                    </div>
                </div>
                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.anticipatedDifficulties,isDetails)">
                    <div class="title">
                        <!-- <span class="must_logo" v-if="isDetails">*</span> -->
                        难点</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.anticipatedDifficulties"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.anticipatedDifficulties"></quillEditor>
                    </div>
                </div>
                <!-- <div class="item" v-if="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.learningObjectivesActivity || isDetails"> -->
                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.learningObjectivesActivity,isDetails)">
                    <div class="title">
                        <!-- <span class="must_logo" v-if="isDetails">*</span> -->
                        活动学习目标</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.learningObjectivesActivity"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.learningObjectivesActivity"></quillEditor>
                    </div>
                </div>
                <div class="item">
                    <div class="title">
                        <span class="must_logo" v-if="isDetails">*</span>
                        活动与游戏进程</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.content"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.content"></quillEditor>
                    </div>
                </div>
                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.resources,isDetails)">
                    <div class="title">
                        <!-- <span class="must_logo" v-if="isDetails">*</span> -->
                        教学准备</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.resources"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.resources"></quillEditor>
                    </div>
                </div>
                <!-- <div class="item" v-if="grade!='16' && (detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.teachingStrategy || isDetails)"> -->
                <div class="item" v-if="grade!='16' && grade!='0' && checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.teachingStrategy,isDetails)">
                    <div class="title">教学策略</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.teachingStrategy"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.teachingStrategy"></quillEditor>
                    </div>
                </div>
                
                <!-- <div class="item" v-if="grade!='16' && (detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.documentation || isDetails)"> -->
                <div class="item" v-if="grade!='16' && checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.documentation,isDetails)">
                    <div class="title">文档记录</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.documentation"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.documentation"></quillEditor>
                    </div>
                </div>

                <div class="item" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.extendedExercises,isDetails)">
                    <div class="title">家庭延伸/拓展活动</div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.extendedExercises"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.extendedExercises"></quillEditor>
                    </div>
                </div>

                <!-- <div class="item reflection" v-if="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection || isDetails"> -->
                <div class="item reflection" v-if="checkContent(detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection,isDetails)">
                    <div class="title">反思 <van-icon v-if="isDetails" @click="showReflectionTemplate" class="icon_reflection" name="question-o" /></div>
                    <div class="text" v-if="!isDetails">
                        <pre class="ql-editor" v-html="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection"></pre>
                    </div>
                    <div v-if="isDetails">
                        <quillEditor :options="editorOption" v-model="detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection"></quillEditor>
                    </div>
                    <start @clearStart="clearStart" :reflectionAfterClassLatitudeVos="detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos"></start>
                </div>
                <div class="item archives" v-if="detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos.length || isDetails">
                    <div class="title">上传档案（照片或视频）</div>
                    <div class="text">
                        <div class="archives_item" v-show="item.resources.flag=='1'" v-for="(item,index) in detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos" :key="index">
                                <div class="top">
                                    <div class="left">
                                        <div class="removeArchives" @click="delectArchives(item.resources)" v-if="isDetails">
                                            <img src="@/assets/img/Layer 45@2x.png" class="clear"/>
                                        </div>
                                        <img :src="item.resources.fileUrl + '?x-oss-process=image/resize,h_220,w_250'" :data-large="item.resources.fileUrl" alt="" v-gallery class="archives_img" v-if="item.resources.fileType === '0'">
                                        <div class="video_box" v-if="item.resources.fileType === '1'" @click="showBigDialog('archives',item.resources)">
                                            <video :poster="item.resources.fileUrl+'?x-oss-process=video/snapshot,t_50,f_jpg,w_400,h_350'" :src="item.resources.fileUrl" v-if="item.resources.fileType === '1'" class="video"></video>
                                            <div class="video-bg">
                                                <img src="@/assets/img/playIcon.png" class="uploadResourceImg"/>
                                            </div>
                                        </div>
                                        <a v-if="item.resources.fileType === '2'" :href="item.resources.fileUrl" target="_blank">
                                            <img src="@/assets/img/file.png" alt="" class="archives_img">
                                        </a>
                                        <img class="archives_img" @click="showBigDialog('archives',item.resources)" v-if="item.resources.fileType == 4" src="@/assets/img/radio.png"/>
                                    </div>
                                    <div class="right" v-if="isDetails">
                                        <van-field v-model="item.resources.fileName" class="text_input" maxlength="30" placeholder="编辑名称" />
                                        <van-field v-model="item.resources.description" maxlength="100" class="bintroduction" type="textarea" rows="1" placeholder="编辑简介" />
                                    </div>
                                    <div class="right" v-if="!isDetails">
                                        <div class="fileName">{{item.resources.fileName}}</div>
                                        <!-- <div class="bintroduction1">{{item.resources.description}}</div> -->
                                        <van-field :disabled="true" style="border:none;" v-model="item.resources.description" class="bintroduction bintroduction1" type="textarea" rows="1" />
                                    </div>
                                </div>
                                <div class="add_label" v-if="isDetails">
                                    <img @click="addStuLabel(index)" class="add_img" src="@/assets/img/Layer add.png" alt="">
                                    <div class="add_text">
                                        添加标签
                                    </div>
                                </div>
                                <div class="stu_img">
                                    <div class="item_img" v-for="(item1,index1) in item.lessonPlannerResourceTags" :key="index1">
                                        <div class="student_avatar">
                                            <img class="student_avatar_img" :src="pictureFuc(item1)" alt="">
                                            <van-icon @click="delectStuImg(index,item1,index1)" v-if="isDetails" class="clear_item" name="clear" />
                                        </div>
                                        <div class="student_name">
                                            {{item1.studentName}}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="item up_item">
                    <uploadFile v-if="isDetails" @addArchives="addArchives"></uploadFile>
                </div>
                <popup @delect="delect" ref="popArchives"></popup>
            </div>
        </div>
        <div class="edit_teacher_plan">
            <van-button round class="btn" @click="detailsPlan()" v-if="isDetails===false">编辑教案</van-button>
            <van-button round class="btn" @click="submitPlan()" v-if="isDetails===true">保存教案</van-button>
        </div>
        <watchDialog ref="watch"></watchDialog>
        
        <van-popup class="pop" v-model="isShowPop">
            <div class="con_box">
            <div class="title">{{text.title}}</div>
            <p>{{text.message}}</p>
            <div class="btn">
                <van-button @click="handleClose()" class="no_save" >{{text.btn.cancelText}}</van-button>
                <van-button @click="handleOk()" class="save" >{{text.btn.okText}}</van-button>
            </div>
            </div>
        </van-popup>
        <popdialog @getTemplateData="getTemplateData" ref="templatePop"></popdialog>
        <!-- 知识点推荐弹框 -->
        <knowledgePop @delectKnowledge="delectKnowledge" @saveKnowledge="saveKnowledge" v-if="isDetails" :detailsData="detailsData" ref="knowledgePop"></knowledgePop>
        <addLabel @studentList="studentList" :subMit="subMit" ref="addLabel"></addLabel>
        <addSelect :subMit1="subMit1" ref="addSelect"></addSelect>
    </div>
</template>
<script>
    const toolbarOptions = [
        // ["bold", "italic"], // 加粗 斜体 下划线 删除线
        // [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
        // ["clean"], // 清除文本格式
    ];
    const gitlPic = require("@/assets/img/profile-girl.jpg");
    const boyPic = require("@/assets/img/profile-boy.jpg");
    import { quillEditor } from "vue-quill-editor";
    import headPortrait from "@/components/header/index"
    import popup from "@/components/popup/index"
    import popdialog from "@/components/popup/index1" //新弹框
    import tableList from "./components/table"
    import watchDialog from "./components/watchDialog"
    import resources from "./components/resources"
    import uploadFile from "./components/uploadFile"
    import start from "./components/start"
    import knowledgePop from "@/components/popup/knowledgePop"
    import { reflectApi, getLessonPlannerDetailApi, editLessonPlannerApi, lockLessonPlannerApi } from "@/api/login/index.js";
    import { getAliyunConfig } from "@/utils/utils";
    import addLabel from './components/addLabel'
    import addSelect from './components/addSelect'
    export default {
        components:{
            headPortrait,
            tableList,
            watchDialog,
            quillEditor,
            resources,
            uploadFile,
            popup,
            start,
            popdialog,
            knowledgePop,
            addLabel,
            addSelect
        },
        data() {
            return {
                cloneOriginalInquiryQuestion:"",//深拷贝的原创探究问题的数据
                studentListData:[],//添加标签的学生列表数据
                knowPoint:{
                    title:"",
                    name:""
                },
                tobanLength:"",
                columns: [
                    { field: "mainAreas", key: "a", title: "学科", align: "center",width: "80px", 
                    renderBodyCell:({row, column, rowIndex},h)=>{
                        console.log(row, column, rowIndex,h)
                        return (
                            <div class="text-bold">
                                {row.contentThatCanBeIntroducedTp.mainAreas}
                            </div>
                        )
                    }
                    },
                    { field: "contentThatCanBeIntroducedTpDetails", key: "b", title: "内容", align: "left",
                        renderBodyCell:({row, column, rowIndex},h)=>{
                            console.log(row, column, rowIndex,h)
                            return h (
                                "div",
                                row.contentThatCanBeIntroducedTpDetails.map((item,index)=>{
                                    console.log(item,index)
                                    if(item.flag == "1"){
                                        return h("p",item.content)
                                    }
                                })
                            )
                           
                        }
                    },
                ],
                tobancolumns:[
                    {field: "mainAreas", key: "a", title: "主要领域", align: "center",width: "120px"},
                    {field: "development", key: "b", title: "发展目标", align: "center",width: "120px"},
                    {field: "target", key: "c", title: "目标", align: "center",width: "200px",
                        renderBodyCell:({row, column, rowIndex},h)=>{
                            console.log(row, column, rowIndex,h)
                            return h (
                                "div",
                                row.target.map((item,index)=>{
                                    console.log(item,index)
                                    return h("div",item)
                                })
                            )
                           
                        }
                    },
                ],
                loading:false,
                //编辑器配置
                editorOption:{
                    placeholder: "请输入内容",
                    theme: "bubble", // or 'bubble'
                    modules:{
                        toolbar:{
                            container: toolbarOptions,
                        }
                    }
                },
                detailsData:{
                    inquiryQuestion:{},
                    lessonPlannerAndActivityDetailVo:{
                        lessonPlanner:{},
                        templateLessonResources:[],
                        lessonPlannerDateDetailVos:[],
                        resources:[],
                        reflectionAfterClassInfoVo:{
                            reflectionAfterClass:{},
                            reflectionAfterClassLatitudeVos:[]
                        },
                        lessonPlannerResourceTagVos:[],
                        contentThatCanBeIntroducedTpVos:[],//知识点推荐
                        activityTeacherRoles:[]
                    }
                },
                isShowMore:0,
                handleQueryShow:true,
                studyArr:[],
                isDetails:false, // 是否为编辑状态

                isShowPop: false,
                text: {
                    title: '提示',
                    message: '是否保存教案？',
                    btn: {
                    okText: '保存',
                    cancelText: '取消'
                    }
                },
                lockData:"",
                goHome:false, // 判断是不是在编辑状态下点击的推出登录
                grade:"",
            }
        },
        methods:{
            studentList(data){
                this.studentListData = data;
            },
            //点击出现下拉选项
            addSelectLabel(data,index,language){
                this.$refs.addSelect.addSelLab(data,index,language)
            },
            //保存下拉
            subMit1(data,index){
                console.log(data,index)
                if(index === 1){
                    this.detailsData.lessonPlannerAndActivityDetailVo.activityPlaces = data;
                }else if (index === 2) {
                    this.detailsData.lessonPlannerAndActivityDetailVo.activityTypes = data;
                } else if (index === 3) {
                    this.detailsData.lessonPlannerAndActivityDetailVo.activityAdultParticipations = data;
                } else if (index === 4) {
                    this.detailsData.lessonPlannerAndActivityDetailVo.activityTeacherRoles = data;
                }
            },
            checkContent(content, isEdit) {
                if (!isEdit) {
                    if (content === '<p><br></p><p><br></p>' || content === '<p><br></p>' || content === null || content === '' || content === undefined) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },
            //头像处理
            pictureFuc(row) {
                if (row.avatar) {
                    return row.avatar + "?x-oss-process=image/resize,h_100,w_100";
                } else {
                    if (row.sex === "0") {
                    return gitlPic;
                    } else {
                    return boyPic;
                    }
                }
            },
            // 删除的学生标签
            delectStuImg(index,item1,index1){
                console.log(index,item1,index1)
                this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags.splice(index1,1);
                if(item1.id){
                    item1.flag = "0";
                    this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].delectTags.push(item1);
                }

            },
            // 保存学生标签
            subMit(data,index){
                let arr =this._.cloneDeep(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags)
                console.log("点击确认,这是复选框里面所有选中的数据",data,index)
                console.log(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags)
                data.forEach((item,index2) => {
                    for(const item1 of this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags){
                        if(item.studentId == item1.studentId){
                            console.log("1111")
                            data.splice(index2,1,item1)
                            break;
                        }
                    }
                })
                console.log("这是得到新增的数据",data)
                this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags = data;
                
                let newArr = this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].delectTags;
                for (let i = 0; i < arr.length; i++) {
                    let isExist = false;
                    for (let j = 0; j < data.length; j++) {
                        if (arr[i].studentId === data[j].studentId) {
                        isExist = true;
                        break;
                        }
                    }
                    if (!isExist) {
                        arr[i].flag = "0";
                        newArr.push(arr[i]);
                    }
                }
                console.log("这是删除的数据",newArr)
                console.log("这是给后台的标签数据",newArr.concat(data))
                // console.log(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags);
            },
            // 新增学生标签
            addStuLabel(index){
                if(this.studentListData.length){
                    this.$refs.addLabel.addStuLabel(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos[index].lessonPlannerResourceTags,index)
                }else{
                    console.log("这是没有学生数据的时候")
                    this.$refs.templatePop.situation = "2";
                    this.$store.commit("setShowTemplatePop",true);
                }
                
            },
            //保存知识点推荐
            saveKnowledge(data){
                console.log(data)
                if(data.contentThatCanBeIntroducedTp.id || data.isEdit){
                    this.detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos.splice(data.rowIndex,1,data)
                }else{
                    this.detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos.push(data)
                }
            },
            // 新增知识点推荐
            addknowledge(){
                if(this.grade == 0){
                    this.$refs.knowledgePop.title = "新增学习内容总结";
                }else {
                    this.$refs.knowledgePop.title = "新增知识点";
                }
                this.$refs.knowledgePop.show = true;
                this.$refs.knowledgePop.dataObj = {
                    contentThatCanBeIntroducedTp:{},
                    contentThatCanBeIntroducedTpDetails:[{flag:"1"}]
                }
                this.$refs.knowledgePop.isEdit = false;
                this.$refs.knowledgePop.getAllKnowledgeData(this.detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos)
            },
            //点击修改知识点推荐
            editRow(row,rowIndex){
                console.log(row,rowIndex)
                row.rowIndex = rowIndex;
                if(this.grade == 0){
                    this.$refs.knowledgePop.title = "修改学习内容总结";
                }else {
                    this.$refs.knowledgePop.title = "修改知识点";
                }
                // this.$refs.knowledgePop.title = "修改知识点";
                this.$refs.knowledgePop.show = true;
                this.$refs.knowledgePop.dataObj = this._.cloneDeep(row);
                this.$refs.knowledgePop.isEdit = true;
            },
            //点击删除知识点推荐
            deleteRow(row,rowIndex){
                console.log(row,rowIndex)
                row.rowIndex = rowIndex;
                this.$refs.knowledgePop.isShowPop = true;
                this.$refs.knowledgePop.delectData = row;
            },
            //删除知识点推荐保存按钮
            delectKnowledge(data){
                console.log(data)
                if(data.contentThatCanBeIntroducedTp.id){
                    this.detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos.splice(data.rowIndex,1);
                    let cloneData = this._.cloneDeep(data);
                    cloneData.contentThatCanBeIntroducedTp.flag = "0";
                    this.detailsData.lessonPlannerAndActivityDetailVo.delectContentThatCanBeIntroduceds.push(cloneData);
                }else{
                    this.detailsData.lessonPlannerAndActivityDetailVo.contentThatCanBeIntroducedTpVos.splice(data.rowIndex,1)
                }
            },
            // 重置星星数量
            clearStart(data){
                console.log(data)
                this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos.forEach(item =>{
                    item.reflectionAfterClassLatitude.starts = 0;
                    item.reflectionAfterClassLatitude.startText = this.textWord(item.reflectionAfterClassLatitude.starts)
                    item.reflectionAfterClassLatitudeDetails.forEach(item1 => {
                        item1.starts = 0;
                    })
                })
            },
            //点击使用模版时候
            getTemplateData(data){
                console.log(data)
                this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection = data;
            },
            //获取反思维度接口
            queryStartData(language){
                console.log("2222")
                reflectApi(language).then(res => {
                    if(!this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos){
                        this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClass = res.data.reflectionAfterClass;
                        this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos = res.data.reflectionAfterClassLatitudeVos;
                    }
                })
            },
            //点击出现反思模版
            showReflectionTemplate(){
                this.$refs.templatePop.situation = "1";
                this.$store.commit("setShowTemplatePop",true);
                this.$refs.templatePop.data = this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClass;
            },
            showLogoOut(){
                this.$store.commit("setIsShowLogo",true);
            },
            changeText(val){
                console.log(val.html)
                console.log(val.html.replace(/<[^>]+>/g, "").trim())
            },
            delect(data){
                console.log('传过来的删除的数据',data);
                this.detailsData.lessonPlannerAndActivityDetailVo.resources.forEach(item =>{
                    if(item.id === data.id){
                        item = data;
                    }
                })
            },
            //删除教案
            delectArchives(item){
                this.$refs.popArchives.isShow = true;
                this.$refs.popArchives.data = item;
            },
            //接受传过来的上传档案的参数
            addArchives(dataArr){
                console.log("传过来的上传档案的参数",dataArr)
                // this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos.push(dataArr);
                let obj = {
                    resources:{},
                    lessonPlannerResourceTags:[],
                    delectTags:[]
                };
                obj.resources = dataArr;
                this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos.push(obj)
            },
            queryPlanDetails(){
                this.loading = true;
                getLessonPlannerDetailApi(this.$route.params.classesId,this.$route.params.schoolYearId,this.$route.params.id).then(res => {
                    console.log("教案数据",res.data);
                    this.detailsData = res.data;
                    //深拷贝原创教案的数据，为了通过这个值来进行原创探究问题的显示与隐藏
                    this.cloneOriginalInquiryQuestion = this._.cloneDeep(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.originalInquiryQuestion);
                    this.tobanLength = this.detailsData.lessonPlannerAndActivityDetailVo.learningDevelopmentGoalsTpYtjjs.length;
                    //这是用来存储底部删除的数据
                    this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos.forEach(item => {
                        this.$set(item,"delectTags",[])
                    })
                    this.$set(this.detailsData.lessonPlannerAndActivityDetailVo,"delectContentThatCanBeIntroduceds",[])
                    this.loading = false;
                    if(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerDateDetailVos.length){
                        this.isShowMore = 
                        this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerDateDetailVos[0].lessonPlannerDateDetails.length;
                        console.log(this.isShowMore)
                    }
                    // 把得到的星星数值除以一千
                    if(this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos){
                        this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos.forEach(item => {
                            item.reflectionAfterClassLatitude.starts = item.reflectionAfterClassLatitude.starts / 1000;
                            item.reflectionAfterClassLatitude.startText = this.textWord(item.reflectionAfterClassLatitude.starts)
                            item.reflectionAfterClassLatitudeDetails.forEach(item1 => {
                                item1.starts = item1.starts / 1000;
                            })
                        })
                    }
                    //学习与发展目标
                    this.studyArr = res.data.lessonPlannerAndActivityDetailVo.learningDevelopmentGoalsYtjjs;
                    //获取反思维度接口
                    this.queryStartData(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.language);
                }).catch(() => {
                    this.loading = false;
                })
            },
            // 提示文字方法
            textWord(starts){
                let text = ""; // eslint-disable-line no-unused-vars
                if(starts > 0 && starts < 4){
                    text = "要加油"
                }else if(starts >= 4 && starts<5){
                    text = "还不错"
                }else if(starts >= 5 ){
                    text = "完美！"
                }else{
                    text = ""
                }
                return text
            },
            //查看更多
            showMore(){
                this.handleQueryShow = !this.handleQueryShow;
            },
            //返回上一页面
            goback(){
                // this.$router.go(-1)
                this.$router.push({
                    name:"list",
                    params:{ id:this.$route.params.classesId,schoolYearId:this.$route.params.schoolYearId}
                })
            },
            // 返回上一页不保存按钮
            handleClose(){
                // this.$router.go(-1)
                this.isDetails = false;
                this.$store.commit("setIsDetails",false)
                if(this.goHome){
                    // this.$router.push("/login");
                    this.$router.push(this.goHome)
                }else{
                    this.$router.push({
                        name:"list",
                        params:{ id:this.$route.params.classesId,schoolYearId:this.$route.params.schoolYearId}
                    })
                }
            },
            //返回上一页保存按钮
            handleOk(){
                if(this.jiaoyanFuc()){
                    return;
                }
                let data = this._.cloneDeep(this.detailsData.lessonPlannerAndActivityDetailVo)
                if(data.lessonPlanner.reflection.replace(/<[^>]+>/g, "").trim()){
                    data.lessonPlanner.isReflection = "1"
                }else{
                    data.lessonPlanner.isReflection = "0"
                }
                data.version = this.lockData;
                let cloneDeepData = this._.cloneDeep(data);
                cloneDeepData.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos.forEach(item => {
                    item.reflectionAfterClassLatitude.starts = item.reflectionAfterClassLatitude.starts * 1000;
                    item.reflectionAfterClassLatitudeDetails.forEach(item1 => {
                        item1.starts = item1.starts * 1000;
                    })
                })
                editLessonPlannerApi(cloneDeepData).then(res => {
                    console.log(res)
                    this.isDetails = false;
                    this.$store.commit("setIsDetails",false)
                    this.$notify({ type: 'success', message: '保存成功' });
                    // this.$router.go(-1)
                    if(this.goHome){
                        // this.$router.push("/login");
                        this.$router.push(this.goHome);
                    }else{
                        this.$router.push({
                            name:"list",
                            params:{ id:this.$route.params.classesId,schoolYearId:this.$route.params.schoolYearId}
                        })
                    }
                    
                })
            },
            //播放视频
            showBigDialog(type,item){
                this.$refs.watch.showDialog(type,item);
            },
            sendResources(item){
                console.log("这是接受过来的资源参数",item)
                this.showBigDialog("resources",item)
            },
            //编辑教案
            detailsPlan(){
                console.log(this.detailsData.lessonPlannerAndActivityDetailVo)
                console.log(this.$route.params.id)
                lockLessonPlannerApi(this.$route.params.id).then(res => {
                    console.log(res)
                    this.isDetails = true;
                    this.columns = [
                        { field: "mainAreas", key: "a", title: this.knowPoint.name, align: "center",width: "80px", 
                            renderBodyCell:({row, column, rowIndex},h)=>{
                                console.log(row, column, rowIndex,h)
                                return (
                                    <div class="text-bold">
                                        {row.contentThatCanBeIntroducedTp.mainAreas}
                                    </div>
                                )
                            }
                        },
                        { field: "contentThatCanBeIntroducedTpDetails", key: "b", title: "内容", align: "left",
                            renderBodyCell:({row, column, rowIndex},h)=>{
                                console.log(row, column, rowIndex,h)
                                return h (
                                    "div",
                                    row.contentThatCanBeIntroducedTpDetails.map((item,index)=>{
                                        console.log(item,index)
                                        if(item.flag == "1"){
                                            return h("p",item.content)
                                        }
                                    })
                                )
                            
                            }
                        },
                        {
                            field: "",
                            key: "c",
                            title: "操作",
                            width: "70px",
                            center: "left",
                            fixed:"right",
                            renderBodyCell: ({ row, column, rowIndex }, ) => {
                                console.log(row, column, rowIndex)
                                return (
                                    <span>
                                        <van-icon on-click={() => this.editRow(row,rowIndex)} class="knowledge_btn" name="edit" />
                                        <br/>
                                        <br/>
                                        <van-icon on-click={() => this.deleteRow(row,rowIndex)} class="knowledge_btn" name="delete" />
                                    </span>
                                );
                            }
                        }
                    ]
                    this.$store.commit("setIsDetails",true)
                    this.lockData = res.data;
                })
            },
            //保存教案
            submitPlan(){
                if(this.jiaoyanFuc()){
                    return;
                }
                let data = this._.cloneDeep(this.detailsData.lessonPlannerAndActivityDetailVo);
                // data.reflectionAfterClassInfoVo = this.detailsData.reflectionAfterClassInfoVo;
                if(data.lessonPlanner.reflection.replace(/<[^>]+>/g, "").trim()){
                    data.lessonPlanner.isReflection = "1"
                }else{
                    data.lessonPlanner.isReflection = "0"
                }
                data.version = this.lockData
                // data.reflectionAfterClassInfoVo.reflectionAfterClass = this.detailsData.reflectionAfterClassInfoVo.reflectionAfterClass;
                // data.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos = this.detailsData.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos;

                let cloneDeepData = this._.cloneDeep(data);
                cloneDeepData.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos.forEach(item => {
                    item.reflectionAfterClassLatitude.starts = item.reflectionAfterClassLatitude.starts * 1000;
                    item.reflectionAfterClassLatitudeDetails.forEach(item1 => {
                        item1.starts = item1.starts * 1000;
                    })
                })
                cloneDeepData.contentThatCanBeIntroducedTpVos = cloneDeepData.contentThatCanBeIntroducedTpVos.concat(cloneDeepData.delectContentThatCanBeIntroduceds)
                this.$delete(cloneDeepData,"delectContentThatCanBeIntroduceds")
                //处理学生标签的数据
                cloneDeepData.lessonPlannerResourceTagVos.forEach(item => {
                    item.lessonPlannerResourceTags = item.lessonPlannerResourceTags.concat(item.delectTags)
                })
                console.log(cloneDeepData)
                editLessonPlannerApi(cloneDeepData).then(res => {
                    console.log("gggggg",res)
                    if(res.code==200){
                        this.queryPlanDetails();
                        this.isDetails = false;
                        this.columns = [
                            { field: "mainAreas", key: "a", title: this.knowPoint.name, align: "center",width: "80px", 
                                renderBodyCell:({row, column, rowIndex},h)=>{
                                    console.log(row, column, rowIndex,h)
                                    return (
                                        <div class="text-bold">
                                            {row.contentThatCanBeIntroducedTp.mainAreas}
                                        </div>
                                    )
                                }
                            },
                            { field: "contentThatCanBeIntroducedTpDetails", key: "b", title: "内容", align: "left",
                                renderBodyCell:({row, column, rowIndex},h)=>{
                                    console.log(row, column, rowIndex,h)
                                    return h (
                                        "div",
                                        row.contentThatCanBeIntroducedTpDetails.map((item,index)=>{
                                            console.log(item,index)
                                            if(item.flag=="1"){
                                                return h("p",item.content)
                                            }
                                            
                                        })
                                    )
                                
                                }
                            },
                        ]
                        this.$store.commit("setIsDetails",false)
                    }
                }).catch(() => {
                    
                })
            },
            jiaoyanFuc(){
                if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.name.replace(/<[^>]+>/g, "").trim()){
                    this.$notify( {type: 'danger', message: '活动名称不能为空' });
                    return true;
                }
                if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.originalInquiryQuestion.trim() && this.cloneOriginalInquiryQuestion){
                    this.$notify( {type: 'danger', message: '原创探究问题不能为空' });
                    return true;
                }
                // if(!this.studyArr.length){
                //     this.$notify( {type: 'danger', message: '长期学习与发展目标不能为空' });
                //     return true;
                // }
                // if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.areasOfFocus.replace(/<[^>]+>/g, "").trim()){
                //     this.$notify( {type: 'danger', message: '重点不能为空' });
                //     return true;
                // }
                // if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.anticipatedDifficulties.replace(/<[^>]+>/g, "").trim()){
                //     this.$notify( {type: 'danger', message: '难点不能为空' });
                //     return true;
                // }
                // if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.learningObjectivesActivity.replace(/<[^>]+>/g, "").trim()){
                //     this.$notify( {type: 'danger', message: '探究活动学习目标不能为空' });
                //     return true;
                // }
                if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.content.replace(/<[^>]+>/g, "").trim()){
                    this.$notify( {type: 'danger', message: '活动与游戏进程不能为空' });
                    return true;
                }
                // if(!this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.resources.replace(/<[^>]+>/g, "").trim()){
                //     this.$notify( {type: 'danger', message: '教学准备不能为空' });
                //     return true;
                // }
                // 反思内容填写了，下面打分也必须填写
                if(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection.replace(/<[^>]+>/g, "").trim()){
                    if(this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection.replace(/<[^>]+>/g, "").trim().length < 10){
                        this.$notify( {type: 'danger', message: '反思至少需要10个字符' });
                        return true
                    }
                    let aa = this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos.every((item) =>{
                        return item.reflectionAfterClassLatitude.starts > 0
                    })
                    if(!aa){
                        this.$notify( {type: 'danger', message: '请填写反思评分' });
                        return true
                    }
                }
                // 打分打了，内容也必须填写
                for(let item of this.detailsData.lessonPlannerAndActivityDetailVo.reflectionAfterClassInfoVo.reflectionAfterClassLatitudeVos) {
                    if(item.reflectionAfterClassLatitude.starts && !this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlanner.reflection.replace(/<[^>]+>/g, "").trim()){
                        this.$notify( {type: 'danger', message: '请填写反思' });
                        return true;
                    }
                }
                for(let item of this.detailsData.lessonPlannerAndActivityDetailVo.lessonPlannerResourceTagVos){
                    if(!item.resources.fileName){
                        this.$notify( {type: 'danger', message: '档案名称不能为空' });
                        return true;
                    }
                }
            }
        },
        mounted(){
            getAliyunConfig()
            this.queryPlanDetails();
            console.log("存储的班级的数据1111",this.$storeLocal.get("classInfo"));
            this.grade = this.$storeLocal.get("classInfo").schoolYear.grade;
            if(this.grade == 0){
                this.knowPoint = {
                    title:"学习内容总结",
                    name:"重点领域",
                };
                this.columns[0].title = "重点领域";
            }else{
                this.knowPoint = {
                    title:"知识点推荐",
                    name:"学科",
                };
                this.columns[0].title = "学科";
            }
        },
        beforeRouteLeave(to,form,next){
            if(this.isDetails){
                next(false)
                this.isShowPop = true;
                if(to.path === "/login" || to.path === "/pd/home"){
                    // this.goHome = true;
                    this.goHome = to.path;
                }
            }else{
                next()
            }
        }
    }
</script>
<style lang="scss" scoped>
    .lesson_plan_details{
        width: 100%;
        height: 100%;
        // position: relative;
        font-size: 28px;

        /deep/p{
            margin: 0 !important;
        }
        .header{
            position: fixed;
            left: 0;
            top: 0;
            // min-height: 420px;
            height: 90px;
            width: 100%;
            background-color: #4DB3B3;
            padding: 23px 43px 10px 43px;
            box-sizing: border-box;
            color: #FFFFFF;
            z-index: 100;
            // border: 1px solid red;

            .plan_id{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 36px;
                font-weight: bold;

                .icon_top{
                    font-size: 40px;
                    font-weight: bold;
                }
                .icon_top_right{
                    width: 50px;
                    height: 40px;
                }

            }

            //让日历字体颜色为黑色
            .van-popup{
                color: black;
            }
        }

        .body{
            overflow: hidden;
            width: 100%;
            height: 100%;

            .loading{
                position: absolute;
                left: 50%;
                top: 50%;
            }

            .boday_content{
                width: 100%;
                position: absolute;
                top: 90px;
                bottom: 110px;
                overflow-y: scroll;
                box-sizing: border-box;

                .bodoy_header{
                    min-height: 300px;
                    width: 100%;
                    background-color: #4DB3B3;
                    padding: 23px 43px 10px 43px;
                    box-sizing: border-box;
                    color: #FFFFFF;
                    z-index: 100;
                    // border: 1px solid red;

                    .head_portrait{
                        // margin-top: 30px;
                    }

                    .header_text{
                        margin-top: 31px;

                        .title{
                            color: #A9E0DD;
                            font-size: 28px;
                        }

                        .content{
                            font-size: 34px;
                            margin-top: 21px;

                            .time_item{
                                display: flex;
                                flex-wrap:wrap;
                                font-size: 30px;
                                width: 100%;
                                // border: 1px solid red;

                                .year_time{
                                    // width: 33%;
                                    // border: 1px solid red;
                                }

                                .clock_time{
                                    display: flex;
                                    flex-wrap:wrap;
                                    justify-content: space-between;
                                    width: 60%;
                                    margin-left: 20px;
                                    // border: 1px solid red;
                                }

                                .clock_time_item{
                                    width: 187px;

                                    .clock_time_item_index{
                                        width: 187px;
                                        height: 43px;
                                        line-height: 43px;
                                        text-align: center;
                                        font-size: 26px;
                                        background-color: #409F9E;
                                        border-radius: 40px 40px;
                                        font-weight: 400;
                                        // margin-left: 34px;
                                        color: #FFFFFF;
                                        margin-bottom: 20px;
                                    }
                                    .is_today{
                                        background-color: #edad0d;
                                    }
                                }
                            }
                        }
                    }

                    .see_more{
                        text-align: center;
                        font-size: 26px;
                    }

                }

                .item{
                    // margin-bottom: 20px;
                    padding: 34px 30px 0 30px;
                    
                    .must_logo{
                        color: red;
                    }

                    .title{
                        color: #4FB9B8;
                        font-size: 34px;
                        font-weight: bold;

                        .icon_reflection{
                            font-size: 36px;
                            font-weight: bold;
                        }
                    }
                    .text{
                        margin-top: 24px;
                        font-size: 32px;
                        width: 100%;
                        word-wrap:break-word;
                        color: #4D4C4D !important;

                        /deep/pre{
                            word-wrap:break-word;
                            width:inherit;
                            display:block;
                            white-space:normal;
                            line-height: 2.0;
                            margin: 0 0;
                        }

                        .active_name{
                            line-height: 1.0;
                        }
                    }
                    .ovfl_width{
                        overflow: hidden;
                        overflow-x: scroll;
                        .table_width{
                            width: 1400px;
                            overflow-x: scroll
                        }
                    }
                    .no_data{
                        margin-top: 20px;
                        text-align: center;
                        color: gray;
                    }

                    /deep/.ql-editor{
                        font-size: 33px !important;
                        padding: 10px 10px;
                        font-family:arial;

                        ol,ul{
                            padding-left: 0;
                        }
                    }

                    .add_img{
                        width: 94px;
                        height: 94px;
                        margin-top: 20px;
                    }
                    .concept{
                        /deep/.van-cell {
                            padding-left: 0;
                            padding-right: 0;
                            .van-field__value {
                                border: 1px solid #ccc;
                                padding: 0 15px;

                                .van-field__control{
                                    font-size: 33px;
                                }
                            }
                        }
                        /deep/.van-field--disabled{

                            .van-cell__value{
                                background: #F5F7FA;
                            }
                            
                        }
                    }
                }

                // 知识点推荐
                .knowledge_table {
                    
                    .knowledge_btn{
                        font-size: 50px;
                        color: #4DB3B3;
                    }

                    .add_btn{
                        display: flex;
                        flex-direction:row-reverse;
                    }
                    .add-line {
                        width: 180px;
                        border-radius: 15px;
                        background-color: #4DB3B3;
                        color: white;
                        margin-top: 20px;
                    }
                    .no_data{
                        margin-top: 20px;
                        text-align: center;
                        color: gray;
                    }

                    /deep/.ve-table-last-column{
                        text-align: center !important;
                    }
                    /deep/.ve-table-body-td {
                    
                            div{
                                margin: 10px 0;
                                p{
                                    margin: 10px 0 !important;
                                }
                            }
                    }
                }

                .reflection{
                    margin-bottom: 40px;
                }

                //上传档案
                .up_item{
                    padding-top: 1px;
                }

                //资源
                .resources{
                    .is_down{
                        -webkit-touch-callout:none;
                        -webkit-user-select:none;
                        -moz-user-select:none;
                        -ms-user-select:none;
                        user-select:none;
                    }
                }
                //学习与发展目标
                .study{
                    .table{
                        width: 100%;
                        overflow-x: scroll;
                        .table_header{
                            width: 1500px;

                            .table_list{
                                text-align: center;
                                td,th{
                                    width: 300px;
                                }
                            }
                            table {
                                border-collapse: collapse;
                            }
                            table, td, th {
                                border: 1px solid black;
                                padding:15px;
                            }
                        }
                        
                    }
                    .no_data{
                        text-align: center;
                        color: gray;
                    }
                }

                //上传教案
                .archives{

                    .archives_item{
                        // display: flex;
                        // justify-content: space-between;
                        margin-bottom: 40px;
                        // border: 1px solid red;
                        .top{
                            display: flex;
                            justify-content: space-between;
                            .left{
                                width: 200px;
                                height: 170px;
                                border: 1px solid #ededed;
                                // border: 1px solid red;
                                position: relative;

                                .removeArchives{
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    z-index: 10;

                                    .clear{
                                        // color: red;
                                        width: 38px;
                                        height: 38px;
                                    }
                                }

                                .archives_img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: scale-down;
                                }

                                .video_box{
                                    width: 200px;
                                    height: 170px;
                                    position: relative;
                                    .video{
                                        width: 100%;
                                        height: 100%;
                                        object-fit: fill;
                                        // object-fit: scale-down;
                                    }
                                    .video-bg{
                                        width: 100%;
                                        height: 100%;
                                        background-color: rgba(0, 0, 0, 0.39) !important;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        .uploadResourceImg{
                                            width: 50px;
                                            height: 50px;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            margin: auto;
                                        }
                                    }
                                }
                                
                            }
                            .right{
                                width: calc(100% - 220px);
                                // border: 1px solid red;
                                // box-sizing: border-box;

                                .text_input{
                                    /deep/.van-field__control{
                                        height: 40px;
                                        padding-left: 10px;
                                        font-size: 30px;
                                    }
                                }

                                .bintroduction{
                                    margin-top: 5px;
                                    font-size: 30px;
                                    color: gray;
                                    height: 124px;

                                    /deep/.van-field__body{
                                        font-size: 30px;

                                        .van-field__control{
                                            padding-left: 10px;
                                        }
                                    }
                                }

                                .fileName{
                                    font-size: 34px;
                                    font-weight: bold;
                                    padding-left: 10px;
                                }

                                .bintroduction1{
                                    margin-top: 5px;
                                    font-size: 30px;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    text-overflow:ellipsis;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    /deep/.van-field__control:disabled{
                                        color: red !important;
                                        -webkit-text-fill-color:gray;
                                    }
                                }

                                .van-cell{
                                    padding: 0;
                                    border: 1px solid #ededed;
                                    font-size: 34px;
                                }

                            }
                        }
                        
                        .add_label{
                            margin-top: 22px;

                            .add_img{
                                width: 94px;
                                height: 94px;
                            }

                            .add_text{
                                font-size: 24px;
                                font-weight: 400;
                                color: #929293;
                            }
                        }

                        .stu_img{
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 30px;
                            max-height: 360px;
                            // overflow: hidden;
                            // overflow-y: scroll;
                            overflow: auto;
                            // border: 1px solid red;
                            .item_img{
                                margin-right: 35px;
                                margin-bottom: 20px;
                                .student_avatar{
                                    position: relative;
                                    .student_avatar_img{
                                        width: 100px;
                                        height: 100px;
                                        border-radius: 50%;
                                    }

                                    .clear_item{
                                        width: 32px;
                                        height: 32px;
                                        position: absolute;
                                        right: -10px;
                                        top: 0px;
                                        color: #a9a9a9;
                                    }
                                }
                            }

                            .student_name{
                                // margin-top: 14px;
                                font-size: 26px;
                                color: #606060;
                                width: 100px;
                                text-align: center;
                                // height: 130px;
                                overflow: hidden;
                                display: -webkit-box;
                                text-overflow:ellipsis;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                        
                    }
                }

                //编辑器样式
                .quill-editor{
                    // border: 1px solid red;
                    // min-height: 400px;
                    margin-top: 10px;

                    // /deep/.ql-editor{
                    //     font-size: 32px !important;
                    //     padding: 10px 10px;

                    //     ol,ul{
                    //         padding-left: 0;
                    //     }
                    // }
                }

                /deep/.ql-editor.ql-blank::before{
                    font-style: normal;
                    color: #7e7e7e6b;
                    background-color:transparent;
                }

                /deep/.ql-container{
                    border: 1px solid #ccc;
                }
            }
        }

        .edit_teacher_plan{
            position: fixed;
            bottom: 30px;
            left: 0;
            width: 100%;
            text-align: center;
            background-color: #FFFFFF;

            .btn{
                width: 689px;
                height: 80px;
                background-color: #4DB3B3;
                margin: 0 auto;
                font-size: 32px;
                color: #FFFFFF;
            }
        }
    }
    .van-popup {
        border-radius: 20px;
        .con_box {
            width: 661px;
            height: 446px;
            line-height: 1;
            color: #4d5c82;
            padding:43px 34px 0  34px;
            box-sizing: border-box;
            .title {
            font-size: 40px;
            // margin-top: 10px;
            margin-bottom: 49px;
            text-align: center;   
            color: #1E1E1E;
            line-height: 53px;
            font-weight: bold;
            }
            > p {
            font-size: 36px;
            padding-left: 7px;
            color: #3F3F3F;
            }
            .btn {
                font-size: 30px;
                margin-top: 140px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .no_save{
                    width: 291px;
                    border-radius: 15px;
                    border: 3px solid #4DB3B3;
                    color: #4FB9B8;
                }

                .save{
                    width: 291px;
                    border-radius: 15px;
                    background-color: #4DB3B3;
                    color: white;
                }
            > span {
                display: block;
                width: 114px;
                background-color: #e0e5f5;
                text-align: center;
                line-height: 44px;
                font-size: 25px;
                margin-left: 30px;
            }
            > span:last-of-type {
                background-color: #1288fe;
                color: #ffffff;
            }
            }
        }
    }
</style>