<!--
 * @Author: your name
 * @Date: 2021-11-29 13:42:57
 * @LastEditTime: 2021-12-16 10:37:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/lesson_plan/details/components/uploadFile.vue
-->
<template>
    <div>
        <div v-if="loadingBar">
            <!--  -->
            <van-circle v-model="uploadInfo.process" layer-color="#ebedf0" :rate="uploadInfo.process" :speed="100" :text="text" />
        </div>
        <!-- <br/> -->
        <van-uploader accept="video/*,image/*" :before-read="beforeRead" :show-file-list="false" :multiple="true" :after-read="afterRead" />
    </div>
</template>
<script>
import { uploadFile } from "@/utils/utils";
export default {
    data(){
        return{
            uploadInfo: {
                process: 0,
                showProgress: false,
                fileUrl: "",
                privateType: 1,
            },
            loadingBar:false
        }
    },
    computed: {
        text() {
            return this.uploadInfo.process.toFixed(0) + '%';
        },
    },
    methods:{
        afterRead(file){
            console.log("文件信息",file)
            // let arr = []
            if(file.length){
                console.log(file.length)
                file.forEach((item,index) => {
                    this.loadingBar = true;
                    uploadFile(item,this.uploadInfo).then(res =>{
                        console.log("阿里云地址",res)
                        let obj = {
                            fileName:res.fileName,
                            fileType:res.fileType,
                            fileSize: res.fileSize,
                            fileSuffix: res.fileSuffix,
                            fileUrl: res.fileUrl,
                            privateType: res.privateType,
                            description:"",
                            name: res.name,
                            flag:"1"
                        }
                        console.log(index,obj)
                        this.$emit("addArchives",obj)
                        this.loadingBar = false;
                    })
                })
            }else{
                this.loadingBar = true;
                uploadFile(file,this.uploadInfo).then(res =>{
                    console.log("阿里云地址",res)
                    let obj = {
                        fileName:res.fileName,
                        fileType:res.fileType,
                        fileSize: res.fileSize,
                        fileSuffix: res.fileSuffix,
                        fileUrl: res.fileUrl,
                        privateType: res.privateType,
                        description:"",
                        name: res.name,
                        flag:"1"
                    }
                    // arr.push(obj)
                    this.$emit("addArchives",obj)
                    this.loadingBar = false;
                })
            }
        },
        //上传之前
        beforeRead(file){
            console.log("上传之前",file)
            // console.log(file.name.substr(file.name.lastIndexOf(".") + 1))
            if(file.length){
                for(let item of file){
                    if(item.type.indexOf("video") > -1){
                        if(item.name.substr(item.name.lastIndexOf(".") + 1)=="mp4"
                            || item.name.substr(item.name.lastIndexOf(".") + 1)=="MP4"
                            || item.name.substr(item.name.lastIndexOf(".") + 1).toLowerCase()=="mov"
                        ){
                            return true;
                        }else{
                            this.$notify( {type: 'danger', message: '该视频格式不支持' });
                            return false;
                        }
                    }
                }
            } else{
                if(file.type.indexOf("video") > -1){
                    if(file.name.substr(file.name.lastIndexOf(".") + 1)=="mp4"
                        || file.name.substr(file.name.lastIndexOf(".") + 1)=="MP4"
                        || file.name.substr(file.name.lastIndexOf(".") + 1).toLowerCase()=="mov"
                    ){
                        return true;
                    }else{
                        this.$notify( {type: 'danger', message: '该视频格式不支持' });
                        return false;
                    }
                }
            }
            return true;
        }
    }
}
</script>
<style lang="scss" scoped>
    /deep/.van-circle{
        width: 200px;
        height: 170px;
    }
    /deep/.van-uploader__upload{
        width: 200px;
        margin: 0 0 20px 0;
    }
</style>
